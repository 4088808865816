import serverVars from 'server-vars';
import { getBuyerId } from 'dibs-cookie-jar';

import { createReduxStore } from '../reducers/reducerUtils';
import { clientRelayEnvironment as createEnvironment } from '../clientRelayEnvironment';
import { setRelayVariables } from '../actions/sharedRelayVariableActions';
import { setIntlVariables } from '../actions/sharedIntlVariablesActions';
import { setUserTypeTrade } from '../actions/sharedMobileActions';
import { CUSTOMER_TYPE_TRADE } from 'dibs-buyer-layout/exports/userStorageConstants';
import { renderEntryComponent } from '../utils/renderEntryComponent';
import { sharedLoginCheckSetUp } from '../authentication/sharedLoginCheck';
import { addLoginHandlers } from 'dibs-buyer-layout/src/utils/loginHandlers';
import { updateToken } from '../actions/authActions';
import { setCurrency } from '../actions/sharedCurrencyActions';

export function createEntry(payload = {}) {
    const {
        Container,
        query,
        store,
        dangerouslyConnectToStore,
        queue = {},
        actions = {},
    } = payload;

    const relayVariables = serverVars.get('relay.variables');
    const intlVariables = serverVars.get('intlVariables');
    const { environment, socketListeners } =
        payload?.environment && payload?.socketListeners
            ? payload
            : createEnvironment(serverVars.get('dbl.relayData'));
    const reduxStore = store || createReduxStore();

    const userType = serverVars.get('userType');
    reduxStore.dispatch(setUserTypeTrade(userType === CUSTOMER_TYPE_TRADE));

    sharedLoginCheckSetUp({ store: reduxStore, environment });

    reduxStore.dispatch(setRelayVariables(relayVariables));
    reduxStore.dispatch(setIntlVariables(intlVariables));

    const forcedCurrency = serverVars.get('forcedCurrency');
    if (forcedCurrency) {
        store.dispatch(setCurrency({ currency: forcedCurrency }));
    }

    if (Container) {
        renderEntryComponent({
            Component: Container,
            store: reduxStore,
            query,
            variables: relayVariables,
            environment,
            containerId: 'js-root',
            dangerouslyConnectToStore,
            runNotificationQueue: queue.runNotificationQueue || (() => {}),
            reinitTracking: actions.reinitTracking,
            socketListeners,
        });
    }

    addLoginHandlers(() => {
        const userId = getBuyerId(document.cookie);
        if (userId) {
            reduxStore.dispatch(updateToken({ userId }));
        }
    });
}
